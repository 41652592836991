<template>
    <layout :card-loading="serviceLoading">
        <div class="card-body" :class="{ 'd-flex align-items-center justify-content-center': error }">
            <div v-if="error">
                <b-alert fade variant="danger" show>
                    <h6 class="alert-heading"><span class="fa fa-exclamation-circle"></span> {{ $t('Something went wrong, try later') }}</h6>
                    <p class="m-0">
                        <template v-if="(error instanceof Object)">
                            {{ this.$t(error.key, error.parameters) }}
                        </template>
                        <template v-else>
                            {{ error }}
                        </template>
                    </p>
                </b-alert>
            </div>
            <template v-else>
                <div class="text-center">
                    <img src="@/assets/images/tendencys.svg" class="img-80 mb-4" alt="" />
                    <h5 class="f-w-600">{{ $t('KYB Review') }}</h5>
                </div>
                <review-form
                    v-bind="formBindings"
                    :service-loading.sync="serviceLoading"
                    @review-created="reviewCreated"
                    @showError="showError"
                />
            </template>
        </div>
    </layout>
</template>

<script type='text/javascript'>
import Layout from '@/components/layout/layout.vue';
import ReviewForm from '@/pages/accounts/kyb/components/review-form.vue';

export default {
    name: 'KYB',
    components: {
        Layout,
        ReviewForm,
    },
    data() {
        return {
            error: null,
            serviceLoading: true,
            formBindings: {},
        }
    },
    methods: {
        load() {
            const serviceData = this.getRouteParams();

            this.formBindings = {
                id: serviceData.id,
                formName: 'kyb',
                country: serviceData.country,
            };
        },
        getRouteParams() {
            this.serviceLoading = true;

            const { id, _v } = this.$route.query;

            if (!_v) {
                this.showParameterError('_v')
                return;
            }

            if (!id) {
                this.showParameterError('id')
                return;
            }

            const decodedServiceData = Buffer.from(_v, 'base64');
            const parsedServiceData = JSON.parse(decodedServiceData.toString())

            return { id, ...parsedServiceData };
        },
        showParameterError(paramName) {
            this.showError('The request is required to have the {parameter} parameter.', { parameter: paramName })
        },
        showError(key, parameters = null) {
            this.error = { key, parameters };
            this.serviceLoading = false;
        },
        async reviewCreated() {
            this.serviceLoading = true;

            const message = JSON.stringify({ message: 'formSentSuccessfully' });
            const encodedMessage = Buffer.from(message).toString('base64');

            this.$router.push({
                name: 'kycStatus',
                params: { status: 'success' },
                query: { _v: encodedMessage }
            });
        },
    },
    beforeMount() {
        this.load();
    }
};
</script>
