<template>
    <div class="review-form">
        <b-alert v-if="previousReview" fade  show class="review-form__alert-info">
            <p class="mb-2"> 
                {{ $t('This client already has a previous review, below is the information of that review:') }}
            </p>
            <ul>
                <li v-if="previousReview.reviewer">
                    <strong>
                        {{ $t('Reviewer') }}
                    </strong>
                    {{ previousReview.reviewer }}
                </li>
                <li v-if="previousReview.reject_type">
                    <strong>
                        {{ $t('Status') }}:
                    </strong>
                    {{ $t(rejectTypesMap[previousReview.reject_type]) }}
                </li>
            </ul>
        </b-alert>
        <div v-for="section in infoInputs" :key="section.id" class="box">
            <h5 class="review-form__title mb-3"><span :class="titleIcons[section.step]"></span> {{ $t(section.title) }}</h5>
            <div class="row">
                <div v-for="(field, fieldName) in section.form" :key="fieldName" class="col-md-6">
                    <b-form-group :label="`${$t(field.label)} ${field.required ? '*' : ''}`" :label-for="fieldName">
                        <b-input-group>
                            <b-form-input
                                v-if="field.el === 'input'"
                                v-model="user[fieldName]"
                                :id="fieldName"
                                :type="field.type"
                                :placeholder="$t(field.label)"
                                disabled
                            />
                            <select-option
                                v-else-if="field.el === 'select'" class="w-100"
                                v-bind="field"
                                v-model="user[fieldName]"
                                :id="fieldName"
                                :name="fieldName"
                                :source="null"
                                disabled
                            />
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <hr class="review-form__separator" />
        </div>
        <v-observer v-if="!serviceLoading" ref="formKyb" tag="form" @submit.prevent="handleSubmit()">
            <div v-for="section in fileInputs" :key="section.id" class="box">
                <h5 class="review-form__title mb-3"><span :class="titleIcons[section.step]"></span> {{ $t(section.title) }}</h5>
                <template v-for="(field, fieldName) in section.form">
                    <div v-if="user[fieldName]" :key="fieldName" class="review-form__file-review">
                        <h6 class="review-form__file-title">{{ $t(field.label) }}</h6>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="review-form__file-container">
                                    <div class="review-form__file-preview" @click="openFile(user[fieldName].source)">
                                        <embed
                                            :src="user[fieldName].source"
                                            type="application/pdf"
                                            frameBorder="0"
                                            scrolling="none"
                                            height="150px"
                                            width="100%"
                                        />
                                    </div>
                                    <div class="review-form__file-info">
                                        <ul>
                                            <li class="review-form__file-info-item">
                                                <span>{{ $t('File name') }}:</span>
                                                {{ user[fieldName].name }}
                                            </li>
                                            <li class="review-form__file-info-item">
                                                <span>{{ $t('File type') }}:</span>
                                                {{ user[fieldName].mimetype }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="review-form__form">
                                    <v-validation 
                                        v-slot="{ validated, passed, errors }"
                                        rules="required"
                                        :name="`${fieldName}_status`"
                                    >
                                        <b-form-group :label="$t('Status')">
                                            <b-input-group>
                                                <b-form-select
                                                    v-model="review.documents[fieldName].status"
                                                    :options="documentStatuses"
                                                    :state="(validated) ? passed : null"
                                                />
                                            </b-input-group>
                                            <b-form-invalid-feedback :state="(validated) ? passed : null">
                                                {{errors[0]}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </v-validation>
                                    <v-validation 
                                        v-slot="{ validated, passed, errors }"
                                        :rules="{ required: review.documents[fieldName].status === 'reject' }"
                                        :name="`${fieldName}_comments`"
                                    >
                                        <b-form-group :label="$t('Comments')">
                                            <b-input-group>
                                                <b-form-textarea
                                                    id="textarea"
                                                    :placeholder="$t('Enter comments...')"
                                                    rows="3"
                                                    v-model="review.documents[fieldName].comments"
                                                    :state="(validated && review.documents[fieldName].status === 'reject') ? passed : null"
                                                    :disabled="review.documents[fieldName].status !== 'reject'"
                                                />
                                            </b-input-group>
                                            <b-form-invalid-feedback :state="(validated) ? passed : null">
                                                {{errors[0]}}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </v-validation>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <hr class="review-form__separator" />
            </div>
            <div class="box">
                <h5 class="review-form__title mb-3"><span class="far fa-clipboard"></span> {{ $t('Review status') }}</h5>
                <v-validation 
                    v-slot="{ validated, passed, errors }"
                    rules="required"
                    name="reject_type"
                >
                    <b-form-group :label="`${$t('Status')} *`">
                        <b-input-group>
                            <b-form-select
                                v-model="review.reject_type"
                                :options="rejectTypes"
                                :state="(validated) ? passed : null"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="(validated) ? passed : null">
                            {{errors[0]}}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </v-validation>
                <v-validation 
                    v-slot="{ validated, passed, errors }"
                    rules="required"
                    name="comments"
                >
                    <b-form-group :label="`${$t('Comments')} *`">
                        <b-input-group>
                            <b-form-textarea
                                id="textarea"
                                :placeholder="$t('Enter comments...')"
                                rows="3"
                                v-model="review.comments.general"
                                :state="(validated) ? passed : null"
                            />
                        </b-input-group>
                        <b-form-invalid-feedback :state="(validated) ? passed : null">
                            {{errors[0]}}
                        </b-form-invalid-feedback>
                    </b-form-group>
                </v-validation>
            </div>
            <div class="row mb-2">
                <div class="offset-md-8 col-md-4">
                    <b-button
                        type="submit"
                        variant="primary"
                        class="w-100"
                        :disabled="saving"
                    >
                        <div v-if="saving">
                            <em class="fa fa-spinner fa-spin"></em>
                        </div>
                        <div v-else>
                            {{ $t('Continue') }}
                        </div>
                    </b-button>
                </div>
            </div>
        </v-observer>
    </div>          
</template>

<script type='text/javascript'>
import FormMixin from '@/plugins/mixin/form.mixin';
import SelectOption from '@/components/fields/SelectOption.vue';

export default {
    name: 'ReviewForm',
    components: { SelectOption },
    mixins: [FormMixin],
    props: {
        id: {
            type: String,
            default: null,
        },
        serviceLoading: {
            type: Boolean,
            default: true,
        },
        formName: {
            type: String,
            default: null,
        },
        country: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            infoInputs: [],
            fileInputs: [],
            saving: false,
            user: {},
            review: {
                reject_type: null,
                documents: {},
                comments: {}
            },
            titleIcons: {
                1: 'far fa-building',
                2: 'far fa-user',
                3: 'far fa-file',
            },
            previousReview: null,
            rejectTypesMap: {
                accept: 'Accept',
                reject: 'Reject',
                final: 'Approved',
                retry: 'Retry',
            },
        };
    },
    computed: {
        documentStatuses() {
            return  [
                { value: null, text: this.$t('Select an option') },
                { value: 'accept', text: this.$t('Accept') },
                { value: 'reject', text: this.$t('Reject') },
            ]
        },
        rejectTypes() {
            return  [
                { value: null, text: this.$t('Select an option') },
                { value: 'final', text: this.$t('Approved') },
                { value: 'retry', text: this.$t('Retry') },
                { value: 'reject', text: this.$t('Reject') },
            ]
        },
    },
    methods: {
        getFormData() {
            const review_data = { ...this.review };
            const documents = review_data.documents;
            delete review_data.documents;
            review_data.reject_labels = [];

            Object.keys(documents).forEach(documentKey => {
                if (documents[documentKey].status === 'reject') {
                    review_data.comments[documentKey] = documents[documentKey].comments;
                    review_data.reject_labels.push(documentKey);
                }
            });

            const statusesMap = {
                'retry': 'process',
                'final': 'complete',
                'reject': 'complete',
            };

            review_data.status = statusesMap[review_data.reject_type];

            return review_data;
        },
        handleError(error) {
            const message = error?.response?.data?.message || this.$t('Something went wrong, try later');
            this.$toasted.global.errorMessage(message);
        },
        openFile(file) {
            window.open(file, '_blank');
        },
        async loadForm() {
            try {
                const params = { name: this.formName, country: this.country };
                const { data } = await this.axiosAccount.get('/api/forms', { params });

                this.infoInputs = data.filter(input => input.step !== 3);
                this.fileInputs = data.filter(input => input.step === 3);

                this.fileInputs.forEach(section => {
                    Object.keys(section.form).forEach(inputKey => {
                        this.$set(this.review.documents, inputKey, {
                            status: null,
                            comments: '',
                        })
                    });
                });
            } catch(error) {
                this.handleError(error);
            }
        },
        async getDefaultformData() {
            try {
                const params = { local: true };
                const { data } = await this.axiosAccount.get(`/api/kyb/${this.id}`, { params });
                const { kyb_result } = data;
                
                if (!data.kyb_result) {
                    this.$emit('showError', 'The form has not yet been sent by the client.');
                    return;
                }

                const { info, review_history } = kyb_result;
                
                this.user = info;

                if (review_history && review_history.length > 0) {
                    const { reject_type, reject_labels, comments } = review_history[0];
                    const { general } = comments;
    
                    this.review.comments.general = general;
                    this.review.reject_type = reject_type;
    
                    Object.keys(this.review.documents).forEach(documentKey => {
                        if (reject_labels.includes(documentKey)) {
                            this.review.documents[documentKey] = {
                                status: 'reject',
                                comments: comments[documentKey],
                            };
                        } else {
                            this.review.documents[documentKey] = {
                                status: 'accept',
                                comments: '',
                            };
                        }
                    });

                    this.previousReview = {
                        reject_type,
                        reviewer: null,
                    };
                }

            } catch(error) {
                this.handleError(error);
            }
        },
        async handleSubmit() {
            if (await this.$refs.formKyb.validate()) {
                this.saveReview();
            }
        },
        async saveReview() {
            try {
                this.saving = true;

                const formData = this.getFormData();
                await this.axiosAccount.put(`/api/kyb/${this.id}/review`, formData);

                this.$emit('review-created');
            } catch(error) {
                this.handleError(error);
            } finally {
                this.saving = false;
            }
        },
    },
    async mounted() {
        await this.loadForm();
        await this.getDefaultformData();

        this.$emit('update:service-loading', false);
    },
};
</script>

<style lang="scss">
.review-form {
    &__title {
        margin-bottom: 20px;
        font-weight: 600;
        color: #000000;
        font-size: 1rem;
    }

    &__separator {
        border-top: 1px solid rgba(148, 148, 148, 0.1);
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__file-title {
        font-size: 0.9rem;
        color: rgba(#000000, 0.7);
    }

    &__file-review {
        margin-bottom: 20px;
    }
    
    &__file-container {
        background-color: #f9fbfc;
        padding: 20px;
        border-radius: 5px;
    }
    
    &__file-preview {
        margin-bottom: 10px;
        cursor: pointer;

        embed {
            pointer-events: none;
        }
    }
    
    &__file-info {
        ul {
            list-style: none;
            padding-left: 0;
        }
    }

    &__file-info-item {
        color: rgba(#000000, 0.8);

        span {
            font-weight: 500;
            color: #000000;
        }
    }

    &__alert-info {
        border: 0 !important;
        border-left: 5px solid #98c2f2 !important;
        background-color: #d7eaf8 !important;
        color: #5f95da !important;
    }
}
</style>
